import ProductHero from "./modules/views/ProductHero";
import React from "react";

const Home = () => {
  return (
    <>
      <ProductHero />
    </>
  );
};

export default Home;
