import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import EmailIcon from "@material-ui/icons/Email";
import Grid from "@material-ui/core/Grid";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import ProductHeroLayout from "./ProductHeroLayout";
import PropTypes from "prop-types";
import React from "react";
import Typography from "../components/Typography";
import backgroundImage from "../../me.png";
import firstAmericanLogo from "../../first-american.svg";
import radianLogo from "../../radian.png";
import thornLogo from "../../thorn.png";
import { withStyles } from "@material-ui/core/styles";
import yahooLogo from "../../yahoo.svg";

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: "#7fc7d9", // Average color of the background image.
    backgroundPosition: "center",
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(4),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
  letsTalk: {
    color: theme.palette.primary.main,
  },
  logo: {
    width: "20%",
    opacity: 0.7,
  },
  thornLogo: {
    width: "20%",
    position: "relative",
    bottom: 3,
    opacity: 0.7,
  },
  radianLogo: {
    width: "20%",
    position: "relative",
    top: 3,
    opacity: 0.7,
  },
});

function ProductHero(props) {
  const { classes } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Grid container>
        <Grid item sm={7} md={7}>
          <Typography
            color="inherit"
            align="center"
            variant="h2"
            marked="center"
          >
            Hi, I'm Mike!
          </Typography>
          <Typography
            color="inherit"
            align="center"
            variant="h5"
            className={classes.h5}
          >
            I build amazing software and even better software <em>teams</em>. I
            have over 12 years of experience helping entrepreneurs and
            businesses meet their goals by developing forward thinking business
            strategies and innovative tech solutions.{" "}
            <span className={classes.letsTalk}>Let's talk.</span>
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={6}
          >
            <img
              src={firstAmericanLogo}
              className={classes.logo}
              alt="First American Logo"
            />
            <img src={yahooLogo} className={classes.logo} alt="yahoo! Logo" />
            <img
              src={thornLogo}
              className={classes.thornLogo}
              alt="Thorn Logo"
            />
            <img
              src={radianLogo}
              className={classes.radianLogo}
              alt="Radian Generation Logo"
            />
          </Box>

          <Grid container justify="space-between" alignItems="center">
            <Grid item sm={6} lg={6}>
              <Box mb={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  size="large"
                  className={classes.button}
                  component="a"
                  startIcon={<EmailIcon />}
                  href="mailto:mike@mikemct.com"
                >
                  Shoot me an email
                </Button>
              </Box>
            </Grid>
            <Grid item sm={6} lg={6}>
              <Box textAlign="right" mb={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  size="large"
                  className={classes.button}
                  component="a"
                  startIcon={<LinkedInIcon />}
                  href="https://www.linkedin.com/in/mikemct/"
                  target="_blank"
                >
                  Connect on LinkedIn
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
