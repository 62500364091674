import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Home from "./Home";
import React from "react";
import withRoot from "./modules/withRoot";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default withRoot(App);
